import { AccountsProvider } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import basescanLight from '@images/logos/basescan-light.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { insertEllipsis } from '../insertEllipsis';
import TraderEpochDetailsTable from './TraderEpochDetailsTable';

/**
 * Page component for displaying trader epoch details
 */
export default function TraderEpochDetailsPage() {
  const { showAlert } = useContext(ErrorContext);
  const navigate = useNavigate();
  const { traderId, epoch } = useParams();

  useEffect(() => {
    if (!traderId || !epoch) {
      showAlert({
        severity: 'error',
        message: 'Invalid trader ID or epoch',
      });
      return;
    }

    // Validate epoch is a number
    if (Number.isNaN(Number(epoch))) {
      showAlert({
        severity: 'error',
        message: 'Invalid epoch - must be a number',
      });
    }
  }, [traderId, epoch, showAlert, navigate]);

  return (
    <Box
      spacing={2}
      sx={{
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box spacing={2} sx={{ padding: '2rem 0', flexShrink: 0 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          sx={{ mb: 4 }}
          onClick={() => navigate('/explorer/proofs')}
        >
          Back to Proofs
        </Button>
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
          style={{ flexShrink: 0 }}
        >
          <img alt='Base Scan' src={basescanLight} style={{ height: '3rem' }} />
          <Typography
            fontFamily='Jost'
            fontSize={32}
            fontWeight={400}
            variant='h2'
          >
            Trader Epoch Details
          </Typography>
        </Stack>
        <Typography sx={{ mt: 3 }} variant='subtitle1'>
          Trader ID: {insertEllipsis(traderId)} | Epoch:{' '}
          {Number(epoch).toLocaleString()}
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </Box>
      <AccountsProvider>
        <TraderEpochDetailsTable epoch={epoch} traderId={traderId} />
      </AccountsProvider>
    </Box>
  );
}
