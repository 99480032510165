const joinUrl = (base, path) => new URL(path, base).href;

const INTERNAL_API_PREFIX = 'internal/';
export const TCA_STATS_URL = `${INTERNAL_API_PREFIX}analytics/tca_stats`;
export const ORDER_SEARCH_URL = `${INTERNAL_API_PREFIX}ems/get_order_table_rows`;

export class ApiError extends Error {}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const getCsrfTokenFromCookies = () => {
  const cookies = document.cookie.split(';');
  const csrfTokenCookie = cookies.find((cookie) =>
    cookie.trim().startsWith('csrftoken=')
  );
  if (!csrfTokenCookie) return null;
  return csrfTokenCookie.split('=')[1];
};

const commonHeaders = ({ csrfToken }) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept-Encoding': 'gzip',
  };

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }
  return headers;
};

const handleResponse = async (response) => {
  if (response.ok) {
    return response.json();
  }

  let errorBody = '';
  try {
    errorBody = await response.json();
  } catch (e) {
    throw new ApiError('Server Error. Please contact your administrator.');
  }

  let errorMsg = errorBody;
  if (errorBody.errors) {
    errorMsg = errorBody.errors;
  } else if (errorBody.error) {
    errorMsg = errorBody.error;
  }

  throw new ApiError(errorMsg);
};

const makeApiCall = async (path, options = {}) => {
  const url = joinUrl(window.location.origin, path);
  const fetchOptions = { ...options };
  fetchOptions.headers = {
    ...options.headers,
    ...commonHeaders({ csrfToken: getCsrfTokenFromCookies() }),
  };

  try {
    const response = await fetch(url, fetchOptions);
    return await handleResponse(response);
  } catch (error) {
    throw new ApiError(error);
  }
};

export const fetchAccountData = ({ startTime, endTime }) => {
  const paramsObj = {
    start_time: startTime,
    end_time: endTime,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/balances_page_data/?${params}`,
    {
      method: 'GET',
    }
  );
};

export const fetchAccountBalances = () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/account_balances`, {
    method: 'GET',
  });
};

export const fetchOrderEntryFormData = () => {
  return makeApiCall('api/order_form_data', {
    method: 'GET',
  });
};

export const fetchNoUserOrderEntryFormData = () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/no_user_order_form_data`, {
    method: 'GET',
  });
};

export const fetchOptionOrderEntryFormData = () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/option_order_form_data`, {
    method: 'GET',
  });
};

export const fetchOrderDetailData = (order_id) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}analytics/order/${order_id}`, {
    method: 'GET',
  });
};

export const fetchSuperOrderDetailData = (orderId) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}ems/order/${orderId}`, {
    method: 'GET',
  });
};

export const fetchPlacements = ({
  orderId,
  statuses = undefined,
  pageSize = 20,
  pageNumber = 1,
}) => {
  const paramsObj = {
    order_id: orderId,
    page_size: pageSize,
    page_number: pageNumber,
  };

  if (statuses) {
    paramsObj.statuses = statuses;
  }

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`api/placements?${params}`, {
    method: 'GET',
  });
};

export const fetchMultiOrderSummaryData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/multi_order_summary/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const fetchMultiOrderBenchmarkData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/multi_order_benchmark/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const fetchMultiOrderDetailData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/multi_order/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const cancelMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/multi_order/${multi_order_id}`, {
    method: 'DELETE',
  });
};

export const pauseMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/pause_multi_order/`, {
    method: 'POST',
    body: JSON.stringify({ multi_order_id }),
  });
};

export const resumeMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/resume_multi_order/`, {
    method: 'POST',
    body: JSON.stringify({ multi_order_id }),
  });
};

export const submitChainedOrder = async (fields = {}) => {
  return makeApiCall('api/chained_orders/', {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const cancelChainedOrder = (chained_order_id) => {
  return makeApiCall(`api/chained_orders/${chained_order_id}`, {
    method: 'DELETE',
  });
};

export const pauseChainedOrder = (chained_order_id) => {
  return makeApiCall('api/pause_chained_order/', {
    method: 'POST',
    body: JSON.stringify({ chained_order_id }),
  });
};

export const resumeChainedOrder = (chained_order_id) => {
  return makeApiCall('api/resume_chained_order/', {
    method: 'POST',
    body: JSON.stringify({ chained_order_id }),
  });
};

export const fetchChainedOrderDetailData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/chained_orders/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const fetchChainedOrderBenchmarkData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/chained_order_benchmark/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const fetchPovOrderChartData = (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/pov_order_chart_data/${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const fetchExchangePairs = (exchange_names, pair) => {
  const params = new URLSearchParams({
    exchange_names: exchange_names.join(','),
    pair,
  });

  return makeApiCall(`${INTERNAL_API_PREFIX}sor/exchange_pairs?${params}`, {
    method: 'GET',
  });
};

export const convertQty = (
  accounts,
  pair,
  qty,
  is_base_asset,
  pre_calculated_price = null,
  convert_to_num_contracts = false
) => {
  const paramsObj = { accounts, pair };

  if (pre_calculated_price) {
    paramsObj.pre_calculated_price = pre_calculated_price;
  }

  if (convert_to_num_contracts) {
    paramsObj.convert_to_num_contracts = convert_to_num_contracts;
  }

  if (is_base_asset) {
    paramsObj.base_asset_qty = qty;
  } else {
    paramsObj.quote_asset_qty = qty;
  }

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}account/convert_qty?${params}`, {
    method: 'GET',
  });
};

export const getPairPrice = (pair, exchange_name) => {
  const paramsObj = { pair, exchange_name };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}sor/get_pair_price?${params}`, {
    method: 'GET',
  });
};

export const submitOrder = (fields = {}) => {
  return makeApiCall('api/orders/', {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const getBulkOrder = (ids) => {
  const paramsObj = { ids };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/bulk_orders?${params}`, {
    method: 'GET',
  });
};

export const getBulkChainedOrders = (ids) => {
  const paramsObj = { ids };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}oms/bulk_chained_orders?${params}`,
    {
      method: 'GET',
    }
  );
};

export const resubmitOrder = (fields = {}) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/resubmit_order`, {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const resubmitRemainingOrder = (fields = {}) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/resubmit_remaining_order`, {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const submitMultiOrder = (fields = {}) => {
  return makeApiCall('api/multi_orders/', {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const submitCancel = (pk, fields = {}) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/cancel_order/${pk}`, {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
};

export const validateOrderCondition = (order_condition) => {
  return makeApiCall('api/validate_order_condition/', {
    method: 'POST',
    body: JSON.stringify({ order_condition }),
  });
};

export const cancelAllOrders = () => {
  return makeApiCall('api/cancel_all_orders/', {
    method: 'POST',
  });
};

export const getPredictionChartData = ({
  exchangeName,
  pair,
  startTime,
  endTime,
  currentTime,
}) => {
  const paramsObj = {
    exchange_name: exchangeName,
    pair,
    start_time: startTime,
    end_time: endTime,
    current_time: currentTime,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}marketdata/get_prediction_chart_data?${params}`,
    {
      method: 'GET',
    }
  );
};

export const calculatePreTradeAnalytics = async ({
  orderAttrs,
  priceLookup,
}) => {
  const data = {
    order_attrs: orderAttrs,
    price_lookup: priceLookup,
  };

  return makeApiCall(
    `${INTERNAL_API_PREFIX}marketdata/calculate_pre_trade_analytics`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const addExchangeAccount = async (
  name,
  exchange,
  api_key,
  api_secret,
  password,
  credential_options
) => {
  const data = {
    name,
    exchange,
    api_key,
    api_secret,
    credential_options,
  };

  if (password) {
    data.password = password;
  }

  return makeApiCall('api/accounts/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const deleteAccount = async (name) => {
  const data = { name };
  return makeApiCall('api/accounts/', {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
};

export const getAccounts = async () => {
  return makeApiCall('api/accounts/', {
    method: 'GET',
  });
};

export const updateAccount = async ({ id, margin_mode }) => {
  const data = { id, margin_mode };
  return makeApiCall('api/accounts/', {
    method: 'PUT',
    body: JSON.stringify(data),
  });
};

export const refreshAccountBalanceCache = async (account_id) => {
  const data = { account_id };

  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/refresh_account_balance_cache/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const calculateDurationForPov = async (
  exchange_names,
  pair,
  base_asset_qty,
  pov_target
) => {
  const paramsObj = {
    exchange_names: exchange_names.join(','),
    pair,
    base_asset_qty,
    pov_target,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}marketdata/calculate_duration_for_pov?${params}`,
    {
      method: 'GET',
    }
  );
};

export const getOrderBook = async (exchange_name, pair) => {
  const paramsObj = {
    exchange_name,
    pair,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}sor/get_order_book?${params}`, {
    method: 'GET',
  });
};

export const getOrderTemplates = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/order_templates`, {
    method: 'GET',
  });
};

export const createOrderTemplate = async (data) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/order_templates`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const deleteOrderTemplates = async (template_ids) => {
  const data = { template_ids };
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/order_templates`, {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
};

export const amendOrder = async (order_id, changes) => {
  const data = { order_id, changes };
  return makeApiCall(`api/amend_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const pauseOrder = async (order_id) => {
  const data = { order_id };
  return makeApiCall(`api/pause_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const pauseAllOrders = async () => {
  return makeApiCall(`api/pause_all_orders/`, {
    method: 'POST',
  });
};

export const resumeOrder = async (order_id) => {
  const data = { order_id };
  return makeApiCall(`api/resume_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const resumeAllOrders = async () => {
  return makeApiCall(`api/resume_all_orders/`, {
    method: 'POST',
  });
};

export const emailHelp = async (order_id) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}ems/get_email_help?order_id=${order_id}`,
    {
      method: 'GET',
    }
  );
};

export const getUserFavouritePairs = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_favourite_pairs/`, {
    method: 'GET',
  });
};

export const addUserFavouritePairs = async (pairs) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_favourite_pairs/`, {
    method: 'POST',
    body: JSON.stringify({ pairs }),
  });
};

export const deleteUserFavouritePairs = async (pairs) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_favourite_pairs/`, {
    method: 'DELETE',
    body: JSON.stringify({ pairs }),
  });
};

export const getOptionData = async (exchange, underlying, date) => {
  const data = {
    exchange,
    underlying,
    date,
  };
  const params = new URLSearchParams(data);

  return makeApiCall(`${INTERNAL_API_PREFIX}sor/get_option_data?${params}`, {
    method: 'GET',
  });
};

export const connectNettingServer = async () => {
  return makeApiCall('dicy/connect/', {
    method: 'POST',
  });
};

export const resetPassword = async (
  user_id,
  old_password,
  new_password,
  confirm_password
) => {
  const data = { user_id, old_password, new_password, confirm_password };
  return makeApiCall(`${INTERNAL_API_PREFIX}account/reset_password/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const logout = async () => {
  // exception for logout path still in django
  return makeApiCall(`account/logout/`, {
    method: 'POST',
  });
};

export const getUserMetadata = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_metadata/`, {
    method: 'GET',
  });
};

export const getTelegramMetadata = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/telegram_metadata`, {
    method: 'GET',
  });
};

export const getPlantStatus = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/plant_status/`, {
    method: 'GET',
  });
};

export const getAdminPanelData = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/admin_panel_data/`, {
    method: 'GET',
  });
};

export const assignAccountPermission = async (account_id, group_id) => {
  const data = { account_id, group_id };

  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/assign_account_permission/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const unassignAccountPermission = async (account_id, group_id) => {
  const data = { account_id, group_id };
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/unassign_account_permission/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
};

export const getTokenPairLookup = async () => {
  return makeApiCall('api/get_token_pairs/', {
    method: 'GET',
  });
};

export const closeBalances = async (max_notional, account_names) => {
  const data = { max_notional, account_names };
  return makeApiCall(`api/close_balances/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const updateUserPreferences = async (preferences) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_preferences/`, {
    method: 'POST',
    body: JSON.stringify(preferences),
  });
};

export const setBetaAgreedAt = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/set_beta_agreed_at/`, {
    method: 'POST',
  });
};

export const getServerIp = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/get_server_ip`, {
    method: 'GET',
  });
};

export const getTableOrders = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}ems/get_order_table_rows`, {
    method: 'GET',
  });
};

export const getMarkoutData = async (orderId) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/get_markout_data?order_id=${orderId}&cut_by=exchange,role`,
    {
      method: 'GET',
    }
  );
};

export const getPointsData = async ({ startTime, endTime, activityPage }) => {
  const paramsObj = {
    start_time: startTime,
    end_time: endTime,
    page_number: activityPage,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/get_points_data?${params}`,
    {
      method: 'GET',
    }
  );
};

export const getAccountExchangeSettings = async (accountIds) => {
  const paramsObj = { account_ids: accountIds.join(',') };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/get_account_exchange_settings?${params}`,
    {
      method: 'GET',
    }
  );
};

export const getContractInfo = async (pair, accountIds) => {
  const paramsObj = { pair, account_ids: accountIds.join(',') };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}sor/get_contract_info?${params}`, {
    method: 'GET',
  });
};

export const getVersionData = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/get_VersionData/`, {
    method: 'GET',
  });
};

export const getCallDynamic = async (url, paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${url}?${params}`, {
    method: 'GET',
  });
};

export const getDicyConnectionMetadata = async () => {
  return makeApiCall('dicy/credentials/', {
    method: 'GET',
  });
};

export const getOrderSearchData = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}ems/get_filter_order`, {
    method: 'GET',
  });
};

export const getOrderTableRows = async (paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}ems/get_order_table_rows?${params}`,
    {
      method: 'GET',
    }
  );
};

export const getOptionOrders = async (paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}ems/get_option_orders?${params}`, {
    method: 'GET',
  });
};

/**
 * Fetches transaction data from Arweave GraphQL endpoint.
 *
 * @param {number} limit - Number of transactions to fetch (default: 10)
 * @param {string|null} cursor - Pagination cursor for fetching next page
 * @param {Object} filters - Additional filters to apply
 * @param {string[]} [filters.ids] - List of transaction IDs to filter by
 * @param {string} [filters.merkle_root] - Merkle root hash to filter by (must be provided with cid)
 * @param {string} [filters.cid] - CID hash to filter by (must be provided with merkle_root)
 * @param {string} [filters.trader_id] - Trader ID to filter by
 * @param {string|number} [filters.epoch] - Epoch number to filter by
 * @returns {Promise<{edges: Array<{
 *   cursor: string,
 *   node: {
 *     id: string,
 *     owner: {address: string},
 *     tags: Array<{name: string, value: string}>,
 *     block: {timestamp: number}
 *   }
 * }>>}} Response containing transaction edges with cursor and node data
 * @throws {Error} If the request fails or returns an error response
 */
export const getArweaveData = async (limit, cursor, filters = {}) => {
  console.log('[getArweaveData] called with ', limit, cursor, filters);
  const data = {
    limit,
    ...filters,
  };
  if (cursor) data.cursor = cursor;

  return makeApiCall(`${INTERNAL_API_PREFIX}analytics/arweave/data/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

/**
 * Fetches raw Arweave transaction data without decryption.
 *
 * @param {string} txId - The Arweave transaction ID to fetch
 * @returns {Promise<{data: {raw_data: string} | {error: string}}>} Response containing:
 *   - On success: {data: {raw_data: string}} - The raw transaction data
 *   - On failure: {data: {error: string}} - Error message if fetch failed
 */
export const getArweaveTransactionRaw = async (txId) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/arweave/transactions/${txId}/raw`,
    {
      method: 'GET',
    }
  );
};

/**
 * Decrypts an Arweave transaction if the user is authorized.
 *
 * @param {string} txId - The Arweave transaction ID to decrypt
 * @param {Object} data - The decryption request data
 * @param {string} data.trader_id - The trader ID associated with the transaction
 * @param {string} data.raw_data - The raw encrypted transaction data to decrypt
 * @returns {Promise<{data: {decrypted_data: any} | {error: string}}>} Response containing:
 *   - On success: {data: {decrypted_data: any}} - The decrypted transaction data
 *   - On failure: {data: {error: string}} - Error message if decryption failed or unauthorized
 */
export const decryptArweaveTransaction = async (txId, data) => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}analytics/arweave/transactions/${txId}/decrypt`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const setup2FA = async () => {
  const response = await makeApiCall(
    `${INTERNAL_API_PREFIX}account/setup_2fa/`,
    {
      method: 'POST',
    }
  );
  return response.qr_code;
};

export const verify2FA = async (token) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/verify_2fa/`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const reset2FA = async (token) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/reset_2fa/`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const verify_login_2FA = async (token) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/verify_2fa_login/`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const link_telegram = async (telegramUsername) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/link_telegram/`, {
    method: 'POST',
    body: JSON.stringify({ telegramUsername }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const unlink_telegram = async (telegramUsername) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/unlink_telegram/`, {
    method: 'POST',
    body: JSON.stringify({ telegramUsername }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getMinQty = async (pair, exchange_name) => {
  const paramsObj = { pair, exchange_name };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${INTERNAL_API_PREFIX}oms/get_min_qty?${params}`, {
    method: 'GET',
  });
};

export const getNettingStats = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}analytics/netting_stats/`, {
    method: 'GET',
  });
};

export const startRebalance = async (fields = {}) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/start_rebalance`, {
    method: 'POST',
    body: JSON.stringify(fields),
  });
};

export const checkRebalance = (account_id) => {
  const paramsObj = { account_id };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/check_rebalance?${params}`,
    {
      method: 'GET',
    }
  );
};

export const checkAccountRebalance = (account_id) => {
  const paramsObj = { account_id };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/check_account_rebalance?${params}`,
    {
      method: 'GET',
    }
  );
};

export const stopRebalance = async (taskId) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/stop_rebalance`, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const stopScheduled = async (taskId) => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/stop_scheduled`, {
    method: 'POST',
    body: JSON.stringify({ task_id: taskId }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getUserReferrals = async () => {
  return makeApiCall(`${INTERNAL_API_PREFIX}account/user_referrals`, {
    method: 'GET',
  });
};

export const getAutoOrderConfig = async (
  urgency,
  exchange_names,
  pair,
  base_asset_qty,
  side
) => {
  const paramsObj = { urgency, exchange_names, pair, base_asset_qty, side };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(
    `${INTERNAL_API_PREFIX}oms/get_auto_order_config?${params}`,
    {
      method: 'GET',
    }
  );
};

export const getKeyManagementFormData = async () => {
  return makeApiCall(
    `${INTERNAL_API_PREFIX}account/key_management_form_data/`,
    {
      method: 'GET',
    }
  );
};
