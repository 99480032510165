import { AccountsContext } from '@/shared/context/AccountsProvider';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { useTheme } from '@emotion/react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { selectBasescanConfig } from '../proofUtils/ProofConfig';
import { useProofsCache } from '../proofUtils/useProofsCache';
import { useTradesPagination } from '../tradeUtils/useTradesPagination';
import DataEventSection from './DataEventSection';
import RiskEventsSection from './RiskEventsSection';
import TradeHistoryTable from './TradeHistoryTable';
import TabPanel from './components/TabPanel';

/**
 * Component that displays detailed information about a trader's epoch, including data and risk attestation events.
 * Shows transaction hashes and detailed event data if the user is authorized to view it.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.traderId - ID of the trader to display details for
 * @param {number|string} props.epoch - Epoch number to display details for
 * @returns {React.ReactElement} Card containing trader epoch details or error message
 */
export default function TraderEpochDetailsTable({ traderId, epoch }) {
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);
  const { isDev } = useUserMetadata();
  const [tabValue, setTabValue] = useState(0);
  const config = selectBasescanConfig(isDev);

  // Use proofs cache instead of trader epoch details
  const { proofs } = useProofsCache(); // proofs are now already sorted
  const proofDetails = useMemo(() => {
    if (!traderId || !epoch) return null;

    const proof = proofs.find(
      (p) => p.traderId === traderId && Number(p.epoch) === Number(epoch)
    );

    if (!proof) return null;

    return {
      dataEventTxHash: proof.dataEvent?.transactionHash || null,
      riskEventTxHashes: proof.riskEvents?.map((e) => e.transactionHash) || [],
      loading: false,
      error: null,
    };
  }, [proofs, traderId, epoch]);

  // Update hook usage
  console.debug(
    '[TraderEpochDetailsTable] calling useTradesPagination with traderId',
    traderId,
    'and epoch',
    epoch
  );
  const {
    trades,
    loading: tradesLoading,
    error: tradesError,
    retryFetch: fetchTrades,
  } = useTradesPagination({
    traderId,
    epoch,
    pageSize: 25,
  });

  const isAuthorized = useMemo(
    () =>
      accounts?.some((acc) => matchesTraderId(acc.hashed_api_key, traderId)),
    [accounts, traderId]
  );

  // Add a simple display section for trades
  const renderTradesSection = () => (
    <TradeHistoryTable
      error={tradesError}
      isAuthorized={isAuthorized}
      loading={tradesLoading}
      retryFetch={fetchTrades}
      trades={trades}
    />
  );

  // Validate inputs
  if (!traderId) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid trader ID
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!epoch || Number.isNaN(Number(epoch))) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid epoch - must be a number
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!proofDetails) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' variant='h6'>
            No transaction hashes found for this epoch
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const { dataEventTxHash, riskEventTxHashes } = proofDetails;

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={(e, newVal) => setTabValue(newVal)}>
        <Tab label={`Data Attestation ${dataEventTxHash ? '(1)' : '(0)'}`} />
        <Tab label={`Risk Attestations (${riskEventTxHashes.length})`} />
        <Tab label={`Trade History (${trades.length})`} />
      </Tabs>

      <Card
        sx={{
          maxWidth: '100%',
          backgroundColor: theme.palette.background.card,
          boxShadow: 'none',
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <TabPanel index={0} value={tabValue}>
            <DataEventSection config={config} txHash={dataEventTxHash} />
          </TabPanel>

          <TabPanel index={1} value={tabValue}>
            <RiskEventsSection config={config} txHashes={riskEventTxHashes} />
          </TabPanel>

          <TabPanel index={2} value={tabValue}>
            {renderTradesSection()}
          </TabPanel>
        </CardContent>
      </Card>
    </Box>
  );
}
