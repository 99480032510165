/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  ThemeProvider,
  Paper,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '@/shared/context/TitleProvider';
import {
  OrderMessages,
  useMultiOrderMessages,
} from '@/shared/orderDetail/OrderMessages';
import { theme } from '@/theme/theme';
import { OrderBenchmarks, OrderSummary } from '@/shared/orderDetail';
import {
  fetchMultiOrderBenchmarkData,
  fetchMultiOrderDetailData,
} from '../../../apiServices';
import { Loader } from '../../../shared/Loader';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import MultiSummary from './MultiSummary';
import { BuySellSpreadChart } from './charts/BuySellSpreadChart';
import { PriceDifferenceChart } from './charts/PriceDifferenceChart';
import { PriceSpreadChart } from './charts/PriceSpreadChart';
import { OrderActions } from '../algoOrderDetails/OrderActions/OrderActions';
import { ChildOrderTable } from './ChildOrderTable';

function MultiOrderDetailsPage() {
  const { uuid } = useParams();
  const [orderBenchmark, setOrderBenchmark] = useState({});
  const [orderSummaryState, setOrderSummaryState] = useState({});
  const [childOrders, setChildOrders] = useState([]);
  const [multiOrderStats, setMultiOrderStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { showAlert } = useContext(ErrorContext);

  const { status, pct_filled } = orderSummaryState;
  const { setTitle } = useTitle();
  useEffect(() => {
    if (status && pct_filled >= 0) {
      setTitle(`${status} (${Math.round(Number(pct_filled))}%) - Multi`);
    }
  }, [status, pct_filled]);

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchMultiOrderDetailData(order_id);
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch order details: ${e.message}`,
      });
      return null;
    }

    setOrderSummaryState(orderData.order);
    setChildOrders(orderData.child_orders);
    setMultiOrderStats(orderData);
    return orderData;
  };

  useEffect(() => {
    let intervalId;
    const order_id = uuid;

    setIsLoading(true);
    const loadData = async () => {
      let benchmarkData;
      try {
        benchmarkData = await fetchMultiOrderBenchmarkData(order_id);
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Failed to fetch order details: ${e.message}`,
        });
        return false;
      }

      setOrderBenchmark(benchmarkData);

      const multiData = await loadOrderData(order_id);
      const isTerminated = multiData.child_orders.every(
        (child) => child.status === 'COMPLETE' || child.status === 'CANCELED'
      );

      if (!multiData || isTerminated) {
        clearInterval(intervalId);
      }

      setIsLoading(false);

      return true;
    };

    let isMounted = true;
    let success = true;

    const pollData = async () => {
      while (isMounted && success) {
        success = await loadData();
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      }
    };

    pollData();

    return () => {
      // Stop polling when the component unmounts or success changes
      isMounted = false;
    };
  }, []);

  const orderMessages = useMultiOrderMessages(multiOrderStats);
  const { is_spread } = multiOrderStats;

  let timeStart;
  let timeEnd;
  let origTimeEnd;
  if (childOrders[0]) {
    timeStart = childOrders[0].time_start;
    timeEnd = childOrders[0].time_end;
    origTimeEnd = childOrders[0].orig_time_end;
  }

  if (isLoading) {
    return (
      <Box height='100%'>
        <Card>
          <CardContent>
            <Loader />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='row' spacing={1} style={{ height: '100%' }}>
        <Paper elevation={0} sx={{ width: '500px', overflowY: 'auto' }}>
          <Stack direction='column' spacing={4}>
            <Box style={{ padding: 1 }}>
              <MultiSummary
                childOrders={childOrders}
                orderSummaryData={orderSummaryState}
              />
            </Box>
            <OrderActions
              multiDetailView
              loadOrderData={loadOrderData}
              OrderSummaryData={orderSummaryState}
            />
            <Box sx={{ height: 'calc(100% - 680px)' }}>
              <OrderMessages orderMessages={orderMessages} />
            </Box>
          </Stack>
        </Paper>
        <Box
          sx={{
            maxHeight: '120vh',
            overflowY: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Container
            disableGutters
            maxWidth={false}
            sx={{
              height: '100%',
              width: '100%',
              padding: 0,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <Stack direction='column' minHeight='100%' spacing={2}>
              {/* Bottom row, left half */}
              <OrderBenchmarks
                isMulti
                benchmarkData={orderBenchmark}
                fillRoleBreakdown={orderSummaryState.fill_role_breakdown}
              />
              <Card
                sx={{
                  width: '100%',
                  height: '55vh',
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardContent style={{ height: '95%' }}>
                  <PriceDifferenceChart
                    data={multiOrderStats}
                    origTimeEnd={origTimeEnd}
                    timeStart={timeStart}
                  />
                </CardContent>
              </Card>
              {is_spread && (
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minWidth: '300px',
                    height: '30vh',
                  }}
                >
                  <CardContent style={{ height: '100%' }}>
                    <Box
                      style={{
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <PriceSpreadChart
                        data={multiOrderStats}
                        origTimeEnd={origTimeEnd}
                        timeEnd={timeEnd}
                        timeStart={timeStart}
                      />
                      <BuySellSpreadChart
                        data={multiOrderStats}
                        origTimeEnd={origTimeEnd}
                        timeEnd={timeEnd}
                        timeStart={timeStart}
                      />
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Card
                sx={{
                  height: !is_spread ? '50%' : '20%',
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardContent style={{ height: '100%', padding: 8 }}>
                  <ChildOrderTable includeHeaders childOrders={childOrders} />
                </CardContent>
              </Card>
            </Stack>
          </Container>
        </Box>
      </Stack>
    </ThemeProvider>
  );
}

export default MultiOrderDetailsPage;
