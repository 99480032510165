import React from 'react';
import { ExchangeIcons } from '@/shared/iconUtil';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Divider, Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ValueTypography } from '@/shared/components/MuiComponents';
import { Loader } from '@/shared/Loader';
import {
  calculateDurationDisplay,
  numberWithCommas,
  smartRound,
  titleCase,
} from '../../util';
import { FillExchangeChart, FillRoleChart } from './charts';
import {
  OrderSummaryAdditional,
  OrderSummaryBottomSection,
} from './orderSummaryComponents';
import { OrderInfo, OrderInfoTypography } from './OrderInfo';
import EmptyBar from '../components/EmptyBar';

const formatInitialValue = (val) => {
  if (!val) {
    return '';
  }

  if (Number(val)) {
    return `(${smartRound(Number(val))}) `;
  }

  return `(${val}) `;
};

function OrderSummaryEntrySkeleton() {
  return (
    <Stack direction='column' spacing={0}>
      <Skeleton height='1.5rem' variant='text' width='20%' />
      <Skeleton height='1.5rem' variant='text' width='100%' />
    </Stack>
  );
}

function OrderSummaryBarSkeleton() {
  return (
    <Stack direction='column' spacing={0}>
      <Skeleton height='1.5rem' variant='text' width='10%' />
      <Skeleton height='2rem' variant='rectangular' width='100%' />
    </Stack>
  );
}

function OrderSummaryFieldsSkeletonBlock() {
  return (
    <>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
      <Grid xs={6}>
        <OrderSummaryEntrySkeleton />
      </Grid>
    </>
  );
}

function OrderSummaryBarsSkeletonBlock() {
  return (
    <>
      <Grid xs={12}>
        <OrderSummaryBarSkeleton />
      </Grid>
      <Grid xs={12}>
        <OrderSummaryBarSkeleton />
      </Grid>
      <Grid xs={12}>
        <OrderSummaryBarSkeleton />
      </Grid>
    </>
  );
}

function OrderSummary({ OrderSummaryData, isSimple = false, isMulti = false }) {
  const isLoading =
    OrderSummaryData === undefined ||
    Object.keys(OrderSummaryData).length === 0;

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <OrderSummaryEntrySkeleton />
        </Grid>
        <OrderSummaryFieldsSkeletonBlock />
        <Grid xs={12}>
          <Divider />
        </Grid>
        <OrderSummaryFieldsSkeletonBlock />
        <OrderSummaryBarsSkeletonBlock />
      </Grid>
    );
  }

  const {
    id,
    account_names,
    pair,
    side,
    parent_order = undefined,
    chained_orders = undefined,
    buy_token_amount,
    buy_token,
    sell_token,
    sell_token_amount,
    limit_price,
    is_reverse_limit_price,
    executed_token,
    base_asset,
    executed_notional,
    pct_filled,
    executed_price,
    time_start,
    duration,
    strategy_params,
    trajectory_name,
    super_strategy_name,
    notes,
    order_condition_normal,
    order_variable_normal,
    order_condition_vars,
    executed_buy_qty,
    executed_qty,
    schedule_discretion,
    engine_passiveness,
    alpha_tilt,
    exposure_tolerance,
    pov_limit,
    pov_target,
    status,
    initial_order_params = {},
    market_type,
    max_otc,
    pos_side,
    resume_condition_normal,
    unique_venues = [],
    quote_asset,
    target_token,
    target_order_qty,
    is_target_base,
    fill_role_breakdown,
    exchange_role_breakdown,
    target_notional,
    points_earned,
    target_base_token,
  } = OrderSummaryData;

  const hasSuperStrategy =
    super_strategy_name !== undefined && super_strategy_name !== null;
  const isPov = !!pov_target;

  const displayTargetToken = target_token;
  const displayNotionalToken = quote_asset;

  const notionalLabel =
    market_type !== 'option' ? displayNotionalToken : 'Contracts';

  const renderDurationOrParticipation = () => {
    if (isPov) {
      return <ValueTypography>{pov_target * 100}%</ValueTypography>;
    }

    return (
      <ValueTypography>
        {initial_order_params.duration &&
          `(${calculateDurationDisplay(initial_order_params.duration)})`}{' '}
        {calculateDurationDisplay(duration)}
      </ValueTypography>
    );
  };

  return (
    <Stack direction='column' spacing={2}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <OrderInfo header='Accounts'>
            <Stack
              direction='row'
              spacing={2}
              sx={{ alignItems: 'center', pl: '4px' }}
            >
              {!isMulti && (
                <ExchangeIcons
                  exchanges={unique_venues}
                  style={{ height: '1rem', width: '1rem' }}
                />
              )}
              <ValueTypography>
                {account_names &&
                  account_names.map((names, index) => {
                    if (index === account_names.length - 1) {
                      return `${names}`;
                    }
                    return `${names}, `;
                  })}
              </ValueTypography>
            </Stack>
          </OrderInfo>
        </Grid>
        <Grid xs={6}>
          <OrderInfoTypography header='Pair' value={pair} />
        </Grid>
        <Grid xs={6}>
          <OrderInfoTypography
            header='Side'
            value={titleCase(side)}
            valueColor={`side.${side}`}
          />
        </Grid>
        <Grid xs={6}>
          <OrderInfo header='Target Quantity'>
            {buy_token_amount ? (
              <ValueTypography>
                {formatInitialValue(initial_order_params.buy_token_amount)}
                {numberWithCommas(smartRound(buy_token_amount))}{' '}
                {market_type !== 'option' ? displayTargetToken : 'Contracts'}
              </ValueTypography>
            ) : (
              <ValueTypography>
                {formatInitialValue(initial_order_params.sell_token_amount)}
                {numberWithCommas(smartRound(sell_token_amount))}{' '}
                {market_type !== 'option' ? displayTargetToken : 'Contracts'}
              </ValueTypography>
            )}
          </OrderInfo>
        </Grid>
        <Grid xs={6}>
          <OrderInfoTypography
            header='Target Notional'
            value={
              target_notional ? (
                `${numberWithCommas(smartRound(target_notional, 2))} ${notionalLabel}`
              ) : (
                <EmptyBar />
              )
            }
          />
        </Grid>
        <Grid xs={6}>
          <OrderInfoTypography
            header={hasSuperStrategy ? 'Strategy' : 'Trajectory'}
            value={
              hasSuperStrategy
                ? `${super_strategy_name} (${trajectory_name})`
                : trajectory_name
            }
          />
        </Grid>
        <Grid xs={6}>
          <OrderInfoTypography
            header={isPov ? 'Target Participation' : 'Duration'}
            value={renderDurationOrParticipation()}
          />
        </Grid>

        {limit_price && limit_price.length > 0 && (
          <Grid xs={6}>
            <OrderInfoTypography
              header={
                <Stack alignItems='center' direction='row'>
                  Limit Price
                  {is_reverse_limit_price && (
                    <Tooltip title='Reverse Limit Price'>
                      <SwapVertIcon fontSize='small' />
                    </Tooltip>
                  )}
                </Stack>
              }
              value={`${formatInitialValue(initial_order_params.limit_price)} ${limit_price}`}
            />
          </Grid>
        )}
      </Grid>

      <OrderSummaryAdditional
        alpha_tilt={alpha_tilt}
        engine_passiveness={engine_passiveness}
        exposure_tolerance={exposure_tolerance}
        max_otc={max_otc}
        notes={notes}
        order_condition_normal={order_condition_normal}
        order_condition_vars={order_condition_vars}
        order_variable_normal={order_variable_normal}
        pos_side={pos_side}
        pov_limit={pov_limit}
        pov_target={pov_target}
        resume_condition_normal={resume_condition_normal}
        schedule_discretion={schedule_discretion}
        strategy_params={strategy_params}
      />
      <Divider />

      <OrderSummaryBottomSection
        executed_notional={executed_notional}
        executed_price={executed_price}
        executed_token={executed_token}
        market_type={market_type}
        pct_filled={pct_filled}
        points_earned={points_earned}
        pov_limit={pov_limit}
        pov_target={pov_target}
        status={status}
        target_base_token={target_base_token}
        time_start={time_start}
      />

      {!isSimple && (
        <div style={{ width: '100%' }}>
          <FillRoleChart
            data={fill_role_breakdown}
            height='32px'
            style={{ paddingRight: '4px' }}
          />
        </div>
      )}
      <div style={{ width: '100%', marginTop: '4px' }}>
        <FillExchangeChart
          data={exchange_role_breakdown}
          height='32px'
          style={{ paddingRight: '4px' }}
        />
      </div>
    </Stack>
  );
}

export { OrderSummary };
