import { Stack, Typography } from '@mui/material';
import React from 'react';

function OrderInfoTypography({
  header,
  value,
  headerColor = 'secondary',
  valueColor = '',
  headerVariant = 'small2',
  valueVariant = 'body3',
}) {
  return (
    <OrderInfo
      header={header}
      headerColor={headerColor}
      headerVariant={headerVariant}
    >
      <Typography color={valueColor} variant={valueVariant}>
        {value}
      </Typography>
    </OrderInfo>
  );
}

function OrderInfo({
  header,
  headerColor = 'secondary',
  headerVariant = 'small2',
  children,
}) {
  return (
    <Stack direction='column' spacing={1}>
      <Typography color={headerColor} variant={headerVariant}>
        {header}
      </Typography>
      {children}
    </Stack>
  );
}

export { OrderInfo, OrderInfoTypography };
