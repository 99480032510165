import { getArweaveTransactionRaw } from '@/apiServices';
import { useDecryptTrade } from '@/pages/explorer/tradeUtils/useDecryptTrade';
import { AccountsContext } from '@/shared/context/AccountsProvider';
import {
  StyledHeaderTableCellWithLine,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import {
  Alert,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { insertEllipsis } from '../insertEllipsis';

const columns = [
  { id: 'trade_id', label: 'Trade ID', width: 200, align: 'left' },
  { id: 'symbol', label: 'Symbol', width: 100, align: 'left' },
  { id: 'side', label: 'Side', width: 80, align: 'right' },
  { id: 'price', label: 'Price', width: 120, align: 'right' },
  { id: 'volume', label: 'Volume', width: 120, align: 'right' },
  { id: 'amount', label: 'Amount', width: 120, align: 'right' },
  { id: 'usd_volume', label: 'USD Volume', width: 140, align: 'right' },
  { id: 'timestamp', label: 'Timestamp', width: 180, align: 'left' },
  { id: 'action', label: 'Action', width: 200, align: 'center' },
];

async function fetchRawData(tradeId) {
  try {
    const rawResponse = await getArweaveTransactionRaw(tradeId);
    return rawResponse?.raw_data || null;
  } catch (err) {
    console.error('Error loading raw transaction data:', err);
    return null;
  }
}

function DecryptedTradeRow({ trade }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accounts } = useContext(AccountsContext);
  const [rawLoading, setRawLoading] = useState(false);
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setRawLoading(true);
      try {
        const data = await fetchRawData(trade.id);
        setRawData(data);
      } catch (error) {
        console.error('Error fetching raw data:', error);
      } finally {
        setRawLoading(false);
      }
    };

    fetchData();
  }, [trade.id]);

  const { decryptedData, decryptLoading, decryptError, decrypt } =
    useDecryptTrade(trade.id, trade.trader_id, rawData, accounts);

  useEffect(() => {
    decrypt();
  }, [accounts, decrypt]);

  if (rawLoading || decryptLoading) {
    return (
      <TableRow>
        <StyledTableCell align='center' colSpan={columns.length}>
          <ScaleLoader color={theme.palette.primary.main} size={8} />
        </StyledTableCell>
      </TableRow>
    );
  }

  if (decryptError) {
    console.error('Decryption failed:', decryptError);
    return (
      <TableRow>
        <StyledTableCell align='center' color='error' colSpan={columns.length}>
          Decryption failed:
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: 1,
              fontFamily: 'monospace',
              p: 1,
            }}
          >
            {decryptError.message}
          </Box>
        </StyledTableCell>
      </TableRow>
    );
  }

  return decryptedData?.map((tradeData, index) => (
    <TableRow hover key={tradeData.trade_id}>
      <StyledTableCell align='left'>
        {insertEllipsis(tradeData.trade_id, 8, 6)}
      </StyledTableCell>
      <StyledTableCell align='left'>
        {tradeData.symbol || 'N/A'}
      </StyledTableCell>
      <StyledTableCell
        align='right'
        sx={{ color: tradeData.side === 'Buy' ? 'success.main' : 'error.main' }}
      >
        {tradeData.side || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {tradeData.price?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {Number(tradeData.volume)?.toLocaleString('en-US', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {Number(tradeData.amount)?.toLocaleString('en-US', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {tradeData.usd_volume?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='left'>
        {new Date(tradeData.timestamp).toLocaleString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })}
      </StyledTableCell>
      <StyledTableCell align='center'>
        <Button
          size='small'
          variant='text'
          onClick={() => navigate(`/explorer/trades/${trade.id}`)}
        >
          View details
        </Button>
      </StyledTableCell>
    </TableRow>
  ));
}

export default function TradeHistoryTable({
  trades,
  loading,
  error,
  retryFetch,
  isAuthorized,
}) {
  const theme = useTheme();

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <ScaleLoader color={theme.palette.primary.main} size={8} />
          </StyledTableCell>
        </TableRow>
      );
    }
    if (error) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Typography color='error'>Error loading trade history</Typography>
          </StyledTableCell>
        </TableRow>
      );
    }
    if (!isAuthorized) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Alert severity='warning' sx={{ my: 2 }}>
              This data is encrypted. Only the trade owner can access this
              information.
            </Alert>
          </StyledTableCell>
        </TableRow>
      );
    }

    return trades.map((trade) => (
      <DecryptedTradeRow key={trade.id} trade={trade} />
    ));
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant='h5'>
        Trade History
      </Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table stickyHeader aria-label='trade-history-table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderTableCellWithLine
                  align={column.align}
                  key={column.id}
                  style={{ width: column.width }}
                >
                  {column.label}
                </StyledHeaderTableCellWithLine>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
