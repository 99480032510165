import { getArweaveData } from '@/apiServices';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { useContext, useEffect, useState } from 'react';
import { PAGINATION_CONFIG, REFRESH_CONFIG } from '../utils/config';
import { useTradesCache } from './useTradesCache';

/**
 * Hook to paginate through trades with caching, auto-fetching, and cursor-based navigation.
 * Uses cursor from the last trade to determine if more data is available.
 * Builds on top of useTradesCache which handles the local storage and deduplication of trades.
 * Handles error states and provides retry functionality.
 * @param {Object} options Configuration options
 * @param {number} [options.pageSize=25] Number of items per page (1-100)
 * @param {string} [options.traderId] Filter by specific trader ID
 * @param {number|string} [options.epoch] Filter by specific epoch
 * @param {Object} [options.dateRange] Date range filter {start, end}
 * @param {boolean} [options.fetchDecodedData=false] Request decoded trade data
 * @param {Function} [options.cacheHook] Custom hook for caching trades (defaults to useTradesCache)
 * @returns {Object} Pagination state and controls
 */
export function useTradesPagination({
  pageSize = PAGINATION_CONFIG.DEFAULT_ROWS,
  cacheHook = useTradesCache,
  traderId = null,
  epoch = null,
  dateRange = null,
}) {
  // Create cache key based on filters
  const { trades, currentPage, updateTrades, updateCurrentPage, tradesLength } =
    cacheHook({ traderId, epoch });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { showAlert } = useContext(ErrorContext);

  // Get cursor from last trade to determine if more data is available
  const lastTrade = trades[trades.length - 1];
  const hasMore = Boolean(lastTrade?.cursor);

  // Add validation before API calls
  const validateParameters = () => {
    if (pageSize < 1 || pageSize > 100) {
      throw new Error(`Invalid pageSize: ${pageSize}. Must be between 1-100`);
    }
    if (epoch && Number.isNaN(Number(epoch))) {
      throw new Error(`Invalid epoch: ${epoch}. Must be a number`);
    }
  };

  const fetchAndCacheTrades = async (cursor = null, isRetry = false) => {
    if (loading) return [];

    setLoading(true);
    setError(null);

    try {
      validateParameters(); // Validate before making API call

      const filters = {
        ...(traderId && { traderId }),
        ...(epoch && { epoch: Number(epoch) }),
        ...(dateRange && { dateRange }),
      };

      const result = await getArweaveData(pageSize, cursor, filters);

      if (!result || result.edges.length === 0) {
        return [];
      }

      const formattedTrades = result.edges.map((edge) => {
        // Get all tags into a map for easier access
        const tags = edge.node.tags.reduce((acc, tag) => {
          acc[tag.name] = tag.value;
          return acc;
        }, {});

        return {
          ...edge.node,
          id: edge.node.id,
          cursor: edge.cursor, // Important: preserve cursor for pagination
          epoch: tags.epoch || '0',
          trader_id: tags.trader_id || '',
          exchange_name: tags.exchange_name || '',
          merkle_root: tags.merkle_root || '',
          ...tags, // Include any other tags
        };
      });

      updateTrades(formattedTrades);
      return formattedTrades;
    } catch (fetchError) {
      console.error('[fetchAndCacheTrades] error:', fetchError);
      setError(fetchError);

      // Handle validation errors
      if (
        fetchError.message.includes('Invalid pageSize') ||
        fetchError.message.includes('Invalid epoch')
      ) {
        showAlert({ severity: 'error', message: fetchError.message });
        return [];
      }

      // Only show alert if this isn't an auto-retry
      if (!isRetry) {
        showAlert({
          severity: 'error',
          message: `Error fetching trades: ${fetchError.message}. Please try again.`,
        });
      }
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (event, newPage) => {
    const requiredTrades = (newPage + 1) * pageSize;

    if (requiredTrades > tradesLength && hasMore) {
      await fetchAndCacheTrades(lastTrade?.cursor);
    }

    updateCurrentPage(newPage);
  };

  // Ensure initial fetch errors are handled
  useEffect(() => {
    if (tradesLength === 0) {
      fetchAndCacheTrades().catch((initialFetchError) => {
        // Use a more descriptive error logging
        setError(initialFetchError);
      });
    }
  }, []);

  // Periodic refresh
  useEffect(() => {
    const interval = setInterval(
      () => fetchAndCacheTrades(),
      REFRESH_CONFIG.INTERVAL
    );
    return () => clearInterval(interval);
  }, []);

  const filteredTrades = trades.filter((trade) => {
    const matchesTrader = traderId
      ? matchesTraderId(traderId, trade.trader_id)
      : true;
    const matchesEpoch = epoch ? Number(epoch) === Number(trade.epoch) : true;
    return matchesTrader && matchesEpoch;
  });
  const currentTrades = filteredTrades.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  // Add retry functionality
  const retryFetch = () => {
    return fetchAndCacheTrades(lastTrade?.cursor, true);
  };

  console.debug(
    '[useTradesPagination] has ',
    filteredTrades?.length,
    'filtered trades with ',
    currentTrades?.length,
    'current page trades',
    'for traderId',
    traderId,
    'and epoch',
    epoch,
    'on page',
    currentPage
  );

  return {
    trades: currentTrades,
    page: currentPage,
    loading,
    error,
    retryFetch,
    handlePageChange,
    hasMore,
    totalItems: trades.length,
    totalPages: Math.ceil(trades.length / pageSize),
  };
}
