import Binance from './binance.svg';
import Bybit from './bybit.svg';
import Okx from './okx.svg';
import Coinbase from './coinbase.svg';
import Deribit from './deribit.svg';
import Gate from './gate.svg';
import Hyperliquid from './hyperliquid.svg';
import Unknown from './unknown.svg';

const ICONS = {
  binance: Binance,
  bybit: Bybit,
  coinbase: Coinbase,
  deribit: Deribit,
  okx: Okx,
  gate: Gate,
  hyperliquid: Hyperliquid,
  default: Unknown,
};

export default ICONS;
