import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const dottedUnderlineStyle = {
  textDecoration: 'underline dotted',
  textDecorationColor: 'rgba(255,255,255,0.5)',
  textDecorationThickness: '2px',
  textUnderlineOffset: '3px',
  color: 'inherit',
};

function LabelTooltip({
  label,
  link = undefined,
  labelTextVariant = 'body1',
  ...tooltipProps
}) {
  return (
    <Tooltip {...tooltipProps}>
      {link ? (
        <a
          href={link}
          rel='noopener noreferrer'
          style={dottedUnderlineStyle}
          target='_blank'
        >
          <Typography color='inherit' variant={labelTextVariant}>
            {label}
          </Typography>
        </a>
      ) : (
        <Typography
          color='inherit'
          style={dottedUnderlineStyle}
          variant={labelTextVariant}
        >
          {label}
        </Typography>
      )}
    </Tooltip>
  );
}

export const TreadTooltipVariant = {
  passiveness: {
    text: 'Passiveness',
    link: 'https://tread-labs.gitbook.io/api-docs/submitting-orders',
    definition:
      'Measure of how passive the engine placements should be (0-1). A higher passiveness will result in deeper quotes in the limit order book.',
  },
  discretion: {
    text: 'Discretion',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters/discretion',
    definition:
      'Measure of how much discretion to give to the engine when tracking the benchmark. A higher discretion may result in more passive limit order fills at the cost of more variance in benchmark tracking.',
  },
  alpha_tilt: {
    text: 'Alpha Tilt',
    link: 'https://tread-labs.gitbook.io/api-docs/submitting-orders',
    definition:
      'Control the skew in order distribution over time to either front-load or back-load your execution schedule.',
  },
  max_otc_percentage: {
    text: 'Max OTC Percentage',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters/max-otc-percentage',
    definition:
      'The engine will execute as much as Max OTC Percentage of the order via RFQs, if RFQ-capable accounts are selected for the order.',
  },
  exposure_tolerance: {
    text: 'Exposure Tolerance',
    link: 'https://tread-labs.gitbook.io/api-docs/multi-spread-orders/exposure-tolerance',
    definition:
      'Threshold for spread between buy and sell to slowing down or speeding up a leg when exceeded (relative to order notional).',
  },
  passive_only: {
    text: 'Passive Only',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#passive-only',
    definition: 'The engine will only post passive limit orders.',
  },
  active_limit: {
    text: 'Active Limit',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#active-limit',
    definition:
      'This causes limit orders to be repegged every second based on passiveness.',
  },
  reduce_only: {
    text: 'Reduce Only',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#reduce-only',
    definition:
      'The engine will only reduce a position. Note that Reduce Only is not available on OKX.',
  },
  strict_duration: {
    text: 'Strict Duration',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#strict-duration',
    definition:
      'The engine will stop execution at the end time set at order submission, regardless of whether the full target quantity has been executed.',
  },
  spot_leverage: {
    text: 'Spot Leverage',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#spot-leverage',
    definition:
      'Spot margin trading for relevant exchanges (Currently only Bybit).',
  },
  dicy: {
    text: 'Dicy',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters#dicy',
    definition: 'Enables DiCy execution logic.',
  },
  strategy_parameters: {
    text: 'Strategy Parameters',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters',
  },
  duration: {
    text: 'Duration',
    link: 'https://tread-labs.gitbook.io/api-docs/submitting-orders',
  },
  dynamic_limit_spread: {
    text: 'Dynamic Limit Spread',
    link: 'https://tread-labs.gitbook.io/api-docs/multi-spread-orders/dynamic-limit-spread',
    definition:
      'The dynamic limit spread is the minimum difference in price (sell leg minus buy leg) at which orders will be placed in their respective limit order books.',
  },
  ool_pause: {
    text: 'OOL Pause',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/ool-pause',
    definition:
      'When ticked, during the period of time where the current market price is beyond your limit price, the duration will not be counted towards your set duration.',
  },
  strategy: {
    text: 'Strategy',
    link: 'https://tread-labs.gitbook.io/api-docs/strategies',
    definition:
      'Dictates the schedule and configurations that the trading engine uses to fill an order',
  },
  trajectory: {
    text: 'Trajectory',
    link: 'https://tread-labs.gitbook.io/api-docs/trajectories',
    definition: 'The planned execution path of an order.',
  },
  max_clip_size: {
    text: 'Max Clip Size',
    link: 'https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters',
    definition: 'The maximum slice size placed at once.',
  },
  enabled_markets_all: {
    text: 'All',
    definition: 'By default, all markets are enabled for this account.',
  },
  market_volume: {
    text: 'Market Volume: ',
    link: 'https://tread-labs.gitbook.io/api-docs/pre-trade-analytics',
  },
  participation_rate: {
    text: 'Participation Rate: ',
    link: 'https://tread-labs.gitbook.io/api-docs/pre-trade-analytics',
  },
  market_volatility: {
    text: 'Market Volatility: ',
    link: 'https://tread-labs.gitbook.io/api-docs/pre-trade-analytics',
  },
  slippage: {
    text: 'Slippage',
    link: 'https://tread-labs.gitbook.io/api-docs/real-time-algo-order-tracking',
    definition:
      'Average executed price relative to mid price at the time of order entry.',
  },
  vwap_slippage: {
    text: 'VWAP Slippage',
    link: 'https://tread-labs.gitbook.io/api-docs/real-time-algo-order-tracking',
    definition:
      'Average executed price relative to market VWAP throughout the duration of the order.',
  },
  exchange_fee: {
    text: 'Exchange Fee',
    link: 'https://tread-labs.gitbook.io/api-docs/real-time-algo-order-tracking',
    definition:
      'Exchange fee shown in quote asset quantity for the entire order.',
  },
  benchmark_participation_rate: {
    text: 'Participation Rate',
    link: 'https://tread-labs.gitbook.io/api-docs/real-time-algo-order-tracking',
    definition: 'Executed quantity as a ratio of reported market volume.',
  },
  notional_exposure: {
    text: 'Notional Exposure',
    link: 'https://tread-labs.gitbook.io/api-docs/real-time-algo-order-tracking',
    definition: 'Total notional exposure of the child orders.',
  },
};

export function TreadTooltip({ variant, labelTextVariant, ...tooltipProps }) {
  const variantConfig = TreadTooltipVariant[variant];
  if (!(variant in TreadTooltipVariant)) {
    throw new Error(`Variant [${variant}] not supported for TreadTooltip`);
  }

  return (
    <LabelTooltip
      label={variantConfig.text}
      labelTextVariant={labelTextVariant}
      link={variantConfig.link}
      title={variantConfig.definition}
      {...tooltipProps}
    />
  );
}

export default LabelTooltip;
