import { CASH_ASSETS, MARKET_TYPES } from '@/constants';
import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { capitalizeFirstLetter, isEmpty, msAndKs } from '@/util';
import { AccountAssetTable } from './AccountAssetTable';

const filterByMarketType = (assets, marketType) => {
  const isCashAsset = (asset) => CASH_ASSETS.includes(asset);
  let absMarketTypeTotalValue = 0;

  let marketTypeTotalValue = 0;
  let marketTypeAssets = [];
  if (marketType === 'cash') {
    marketTypeAssets = assets.filter((asset) => {
      if (isCashAsset(asset.symbol)) {
        marketTypeTotalValue += asset.notional;
        absMarketTypeTotalValue += Math.abs(asset.notional);

        return true;
      }
      return false;
    });
  } else if (marketType === 'spot') {
    marketTypeAssets = assets.filter((asset) => {
      if (asset.market_type === marketType && !isCashAsset(asset.symbol)) {
        marketTypeTotalValue += asset.notional;
        absMarketTypeTotalValue += Math.abs(asset.notional);
        return true;
      }
      return false;
    });
  } else {
    marketTypeAssets = assets.filter((asset) => {
      if (asset.market_type === marketType) {
        marketTypeTotalValue += asset.notional;
        absMarketTypeTotalValue += Math.abs(asset.notional);

        return true;
      }
      return false;
    });
  }
  return { marketTypeAssets, marketTypeTotalValue, absMarketTypeTotalValue };
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  borderRadius: '8px',
  backgroundColor: 'None',
  '&:hover': {
    backgroundColor: 'None',
  },
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
  ],
}));

const displayMarketType = (marketType) => {
  if (marketType === 'perp') {
    return 'Perpetual Futures';
  }
  return capitalizeFirstLetter(marketType);
};

function MarketTypeCard({
  marketType,
  marketTypeTotalValue,
  marketTypeAssets,
  absMarketTypeTotalValue,
}) {
  const [expanded, setExpanded] = useState(true);
  const theme = useTheme();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack direction='column' spacing={1}>
      <Stack
        alignItems='baseline'
        direction='row'
        spacing={4}
        sx={{
          width: '100%',
        }}
      >
        {/* Left-aligned items */}
        <Typography color='text.secondary' fontWeight={300} variant='h6'>
          {displayMarketType(marketType)}
        </Typography>
        <Typography fontWeight={300} variant='h4'>
          {msAndKs(marketTypeTotalValue)}
        </Typography>
        <Typography variant='subtitle1'>USDT</Typography>

        {/* Spacer to push the ExpandMore to the right */}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            borderRadius: '8px',
            backgroundColor: theme.palette.button.grey,
            '&:hover': {
              backgroundColor: theme.palette.button.lightGrey,
            },
          }}
        >
          <ExpandMore
            aria-expanded={expanded}
            aria-label='show more'
            expand={expanded}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Stack>

      {/* Collapsible content */}
      <Collapse unmountOnExit in={expanded} timeout='auto'>
        <AccountAssetTable
          absMarketTypeTotalValue={absMarketTypeTotalValue}
          marketTypeAssets={marketTypeAssets}
        />
      </Collapse>
    </Stack>
  );
}

export const MarketTypeTable = ({ assets }) => {
  return MARKET_TYPES.map((marketType) => {
    const { marketTypeAssets, marketTypeTotalValue, absMarketTypeTotalValue } =
      filterByMarketType(assets, marketType);
    if (!marketTypeAssets || isEmpty(marketTypeAssets)) {
      return null;
    }

    return (
      <Box key={`${marketType}card`} sx={{ height: '100%' }}>
        <MarketTypeCard
          absMarketTypeTotalValue={absMarketTypeTotalValue}
          marketType={marketType}
          marketTypeAssets={marketTypeAssets}
          marketTypeTotalValue={marketTypeTotalValue}
        />
      </Box>
    );
  });
};
